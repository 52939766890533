import * as THREE from 'three'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
/**
 * Debug
 */

const positions = [
    { x:-2, y: 1.5, z:4, rotX: 0, rotY:0, rotZ:0 },
    { x: 0, y: 15, z: 0, rotX:-1.5 , rotY:0, rotZ: 0 },
    { x: 1, y: .9, z: -4.5, rotX: -.2 , rotY: .4, rotZ:0 },
    { x: 1.2, y: .8, z: -4.9, rotX: -.2 , rotY: 1.5, rotZ:0 }
];
const parameters = {
    materialColor: '#ffeded'
}
const objectsDistance = 4

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Test cube
 */
const cube = new THREE.Mesh(
    // new THREE.BoxGeometry(1, 1, 1),
    // new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
scene.add(cube)

/**
 * Particles
 */
// Geometry
const particlesCount = 200
const particlePositions = new Float32Array(particlesCount * 3)
for(let i = 0; i < particlesCount; i++)
    {
        particlePositions[i * 3 + 0] = (Math.random() - 0.5) * 10
        particlePositions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * positions.length
        particlePositions[i * 3 + 2] = (Math.random() - 0.5) * 10
    }
const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3))
// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03
})
// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Models
 */

const gltfLoader = new GLTFLoader()
gltfLoader.load(
    '/models/scene.glb',
    (gltf) =>
        {
            console.log(gltf)
            gltf.scene.scale.set(0.01, 0.01, 0.01)
            scene.add(gltf.scene.children[0])
            // scene.add(gltf.scene.children[1])
            // for(const child of gltf.scene.children)
            // {
            //     scene.add(child)
            // }
        },
//   () =>
//         {
//             console.log('Progress')
//         },
//   () =>
//         {
//             console.log('Error')
//         },                
)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))   
})

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})
/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.rotation.x = -.2
camera.position.z = 6
camera.position.y = 2
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearAlpha(0)

/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
let smoothScrollY = window.scrollY
const smoothingFactor = 0.1

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime


    // for(const child of scene.children)
    //     {child.rotation.x = elapsedTime * 0.01
    //     child.rotation.y = elapsedTime * 0.012
    //     }
    // Animate meshes            
     camera.position.y = - scrollY / sizes.height * objectsDistance

    const parallaxX = cursor.x
    const parallaxY = - cursor.y
    smoothScrollY += (window.scrollY - smoothScrollY) * smoothingFactor
    interpolateCameraPosition(smoothScrollY)
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) *.5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * .5 * deltaTime
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

}

tick()

let currentTargetIndex = 0;

function lerp(start, end, t) {
    return start * (1 - t) + end * t;
}


function interpolateCameraPosition(smoothScrollY) {
    const scrollRange = document.body.scrollHeight - window.innerHeight;
    const scrollPercent = scrollY / scrollRange;
    
    const numPositions = positions.length
    const segmentLen = 1 / (numPositions - 1)

    let currentTargetIndex = Math.floor(scrollPercent/ segmentLen)
    let nextTagetIndex = currentTargetIndex +1

    currentTargetIndex = Math.min(currentTargetIndex, numPositions -1)
    nextTagetIndex = Math.min(nextTagetIndex, numPositions -1)

    const segmentScollPercent = ((scrollPercent % segmentLen)/segmentLen)
    
    const startPos = positions[currentTargetIndex];
    const endPos = positions[nextTagetIndex];
    const t = (scrollPercent % 0.5) * 2; // Calculate interpolation factor

    camera.position.x = lerp(startPos.x, endPos.x, segmentScollPercent);
    camera.position.y = lerp(startPos.y, endPos.y, segmentScollPercent);
    camera.position.z = lerp(startPos.z, endPos.z, segmentScollPercent);
    
    camera.rotation.x = lerp(startPos.rotX, endPos.rotX, segmentScollPercent);
    camera.rotation.y = lerp(startPos.rotY, endPos.rotY, segmentScollPercent);
    camera.rotation.z = lerp(startPos.rotZ, endPos.rotZ, segmentScollPercent);
}

window.addEventListener('scroll', () => {

    smoothScrollY = window.scrollY

});


